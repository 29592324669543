import type { JSONSchemaType } from 'ajv';

export const strictNumberSchema: JSONSchemaType<number> = {
  type: 'number',
};

export const nullableNumberSchema: JSONSchemaType<number> = {
  type: 'number',
  nullable: true,
};
